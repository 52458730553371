@font-face {
  font-family: "granula-compact-light";
  src: url("./assets/fonts/compactlight/Granula-CompactLight.eot");
  src: url("./assets/fonts/compactlight/Granula-CompactLight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/compactlight/Granula-CompactLight.woff2")
      format("woff2"),
    url("./assets/fonts/compactlight/Granula-CompactLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-compact-medium";
  src: url("./assets/fonts/compactmedium/Granula-CompactMedium.eot");
  src: url("./assets/fonts/compactmedium/Granula-CompactMedium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/compactmedium/Granula-CompactMedium.woff2")
      format("woff2"),
    url("./assets/fonts/compactmedium/Granula-CompactMedium.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-bold";
  src: url("./assets/fonts/bold/Granula-Bold.eot");
  src: url("./assets/fonts/bold/Granula-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/bold/Granula-Bold.woff2") format("woff2"),
    url("./assets/fonts/bold/Granula-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-light";
  src: url("./assets/fonts/light/Granula-Light.eot");
  src: url("./assets/fonts/light/Granula-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/light/Granula-Light.woff2") format("woff2"),
    url("./assets/fonts/light/Granula-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-medium";
  src: url("./assets/fonts/medium/Granula-Medium.eot");
  src: url("./assets/fonts/medium/Granula-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/medium/Granula-Medium.woff2") format("woff2"),
    url("./assets/fonts/medium/Granula-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-wide-bold";
  src: url("./assets/fonts/widebold/Granula-WideBold.eot");
  src: url("./assets/fonts/widebold/Granula-WideBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/widebold/Granula-WideBold.woff2") format("woff2"),
    url("./assets/fonts/widebold/Granula-WideBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-wide-light";
  src: url("./assets/fonts/widelight/Granula-WideLight.eot");
  src: url("./assets/fonts/widelight/Granula-WideLight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/widelight/Granula-WideLight.woff2") format("woff2"),
    url("./assets/fonts/widelight/Granula-WideLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-spatial-light";
  src: url("./assets/fonts/spatiallight/Granula-SpatialLight.eot");
  src: url("./assets/fonts/spatiallight/Granula-SpatialLight.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/spatiallight/Granula-SpatialLight.woff2")
      format("woff2"),
    url("./assets/fonts/spatiallight/Granula-SpatialLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "granula-spatial-bold";
  src: url("./assets/fonts/spatialbold/Granula-SpatialBold.eot");
  src: url("./assets/fonts/spatialbold/Granula-SpatialBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/spatialbold/Granula-SpatialBold.woff2") format("woff2"),
    url("./assets/fonts/spatialbold/Granula-SpatialBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gotham-bold";
  src: url("./assets/fonts/gotham/Gotham-Bold.eot");
  src: url("./assets/fonts/gotham/Gotham-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/gotham/Gotham-Bold.woff2") format("woff2"),
    url("./assets/fonts/gotham//Gotham-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gotham-book";
  src: url("./assets/fonts/gotham/Gotham-Book.eot");
  src: url("./assets/fonts/gotham/Gotham-Book.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/gotham/Gotham-Book.woff2") format("woff2"),
    url("./assets/fonts/gotham//Gotham-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gotham-medium";
  src: url("./assets/fonts/gotham/Gotham-Medium.eot");
  src: url("./assets/fonts/gotham/Gotham-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/gotham/Gotham-Medium.woff2") format("woff2"),
    url("./assets/fonts/gotham//Gotham-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "arimo-bold";
  src: url("./assets/fonts/arimo/Arimo-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "arimo-medium";
  src: url("./assets/fonts/arimo/Arimo-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "arimo-regular";
  src: url("./assets/fonts/arimo/Arimo-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'abcg-into-nord-bold';
  src: url("./assets/fonts/abcgintonord-bold/ABCGintoNord-Bold.eot");
  src: url("./assets/fonts/abcgintonord-bold/ABCGintoNord-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/abcgintonord-bold/ABCGintoNord-Bold.woff2") format("woff2"),
    url("./assets/fonts/abcgintonord-bold/ABCGintoNord-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'abcg-into-nord-light';
  src: url("./assets/fonts/abcgintonord-light/ABCGintoNord-Light.eot");
  src: url("./assets/fonts/abcgintonord-light/ABCGintoNord-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/abcgintonord-light/ABCGintoNord-Light.woff2") format("woff2"),
    url("./assets/fonts/abcgintonord-light/ABCGintoNord-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'abcg-into-nord-regular';
  src: url("./assets/fonts/abcgintonord-regular/ABCGintoNord-Regular.eot");
  src: url("./assets/fonts/abcgintonord-regular/ABCGintoNord-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/abcgintonord-regular/ABCGintoNord-Regular.woff2") format("woff2"),
    url("./assets/fonts/abcgintonord-regular/ABCGintoNord-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'abcg-into-nord-medium';
  src: url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.eot");
  src: url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.woff2") format("woff2"),
    url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'abcg-into-nord-semibold';
  src: url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.eot");
  src: url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.woff2") format("woff2"),
    url("./assets/fonts/abcgintonord-medium/ABCGintoNord-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
