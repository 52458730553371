/* weird fix for resetting the autofill background color in input fields 🤔 */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.cssDebug {
  outline: 1px solid lime;
}

.cssDebug * {
  outline: 1px solid lime;
}
